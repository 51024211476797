/* General imports */
import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import Button from '@mui/material/Button';

/* Styled components */
const FileUpload = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 150px;
    align-items: center;
    justify-content: space-evenly;
    span {
        text-align: center;
    }
`

function FileInput (props) {

    const onChange = (e) => {
        props.onChange(e);
    }
    return (
        <FileUpload>
            <input
                
                id="contained-button-file"
                name='attachment'
                type="file"
                style={{ display: "none" }}
                onChange={onChange}
                required={props.required}
                multiple={props.multiple}
            />
            <span>
                {typeof props.file === "string" ? props.file : props.file ? props.file.length > 1 ? `${props.file.length} files` : props.file[0].name : 
                    translate("No file selected")
                }
            </span>
            <label htmlFor="contained-button-file">
                <Button
                variant="contained"
                color="primary"
                component="span"
                >
                    {
                        props.file ?
                        translate("Replace files")
                        :
                        translate("Upload files")
                    }
                </Button>
            </label>
        </FileUpload>
    );
}

export default FileInput;
