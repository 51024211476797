import { Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ThemeContext } from "../../providers/theme";
import NoImage from "../../images/no-image.png";
import ReactPlayer from "react-player";
import NewMedia from "./NewMedia";
import hasScope from "../../providers/authentication/hasScope";
import { useAuth } from "../../providers/authentication";

/* Styled components */
const DeleteButton = styled.div`
  position: absolute;
  display: none;
  top: 10px;
  right: 10px;
  z-index: 99;
  transition: all 300ms ease-in-out;
  color: ${(props) => props.theme.palette.accent};
  &:hover {
    transform: scale(1.1);
  }
`;
const EditButton = styled.div`
  position: absolute;
  display: none;
  top: 35px;
  right: 10px;
  z-index: 99;
  transition: all 300ms ease-in-out;
  color: ${(props) => props.theme.palette.accent};
  &:hover {
    transform: scale(1.1);
  }
`;

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:hover ${DeleteButton}, &:hover ${EditButton} {
    display: flex;
  }
`;

const Thumbnail = styled.img`
  position: relative;
  width: 100%;
  height: calc(0.45 * 100vw);
  object-position: center;
  object-fit: contain;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  @media (min-width: 600px) {
    height: calc(0.225 * 100vw);
  }
  @media (min-width: 1024px) {
    height: calc(0.125 * 100vw);
  }
`;

const ThumbnailVideo = styled.video`
  position: relative;
  width: 100%;
  height: calc(0.45 * 100vw);
  object-position: center;
  object-fit: contain;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  @media (min-width: 600px) {
    height: calc(0.225 * 100vw);
  }
  @media (min-width: 1024px) {
    height: calc(0.125 * 100vw);
  }
`;

const ContentModal = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  outline: none;
  @media (min-width: 1024px) {
    /* height: auto;
        width: auto; */
  }
`;

const ModalImage = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
`;

const ModalVideo = styled.video`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
`;

const Metadata = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 150px;
  height: auto;
  padding: 10px;
  z-index: 999;
`;

const ModalMetadata = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 50%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  z-index: 999;
`;

const RowData = styled.span`
  margin: 5px 0;
  font-size: 0.8rem;
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.span`
  font-weight: 900;
  font-size: 1rem;
`;

const MediaDate = styled.span`
  font-weight: 700;
  font-size: 0.85rem;
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 0.85rem;
`;

const ContentVideo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  margin: auto;
`;

const Media = ({ data, reload, handleDelete }) => {
  const [editTimestamp, setEditTimestamp] = useState();

  const { token } = useAuth();

  const intl = useIntl();

  const theme = useContext(ThemeContext);

  const LIMIT_DESC = 200;

  const [open, setOpen] = React.useState(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    reload();
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditItem = (e) => {
    e.stopPropagation();
    handleOpenEdit();
  };

  const onDelete = (e) => {
    e.stopPropagation();
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      handleDelete(data.id);
    }
  };

  return (
    <Root>
      <DeleteButton hoverColor={theme.palette.base} onClick={onDelete}>
        <DeleteForeverIcon />
      </DeleteButton>
      {data.horseRider && hasScope(token, "editTraining") && (
        <EditButton hoverColor={theme.palette.base} onClick={handleEditItem}>
          {theme.icons.edit}
        </EditButton>
      )}

      {data.type === "photo" ? (
        <Thumbnail
          src={`${data.url}?ts=${editTimestamp}`}
          alt={`media`}
          onClick={handleOpen}
        />
      ) : data.type === "video" ? (
        <ThumbnailVideo
          src={`${data.url}#t=0.5?ts=${editTimestamp}`}
          alt={`media`}
          onClick={handleOpen}
          preload="metadata"
        />
      ) : (
        <Thumbnail src={NoImage} alt={`no image`} />
      )}
      <Metadata>
        <Title>
          {data.name && <Name>{data.name}</Name>}
          {data.date && (
            <MediaDate>{`${intl.formatDate(new Date(`${data.date}T00:00:00Z`), {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}`}</MediaDate>
          )}
        </Title>
        {data.description && (
          <Description dangerouslySetInnerHTML={{ __html: data.description }} />
        )}
        {data.location && (
          <RowData>
            <strong>{`${intl.formatMessage({ id: "location" })}: `}</strong>
            {data.location.name}
          </RowData>
        )}
        {data.responsible && (
          <RowData>
            <strong>{`${intl.formatMessage({ id: "responsable" })}: `}</strong>
            {data.responsible.fullName}
          </RowData>
        )}
        {data.club && (
          <RowData>
            <strong>{`${intl.formatMessage({ id: "club" })}: `}</strong>
            {data.club}
          </RowData>
        )}
        {data.horseRider && (
          <RowData>
            <strong>{`${intl.formatMessage({ id: "rider" })}: `}</strong>
            {data.horseRider.fullName}
          </RowData>
        )}
        {data.jump && (
          <RowData>
            <strong>{`${intl.formatMessage({ id: "jump" })}: `}</strong>
            {data.jump === "completedTest"
              ? `${intl.formatMessage({ id: "completedTest" })}`
              : `${data.jump} ${intl.formatMessage({ id: "meters" })}`}
          </RowData>
        )}
        {data.faults && (
          <RowData>
            <strong>{`${intl.formatMessage({ id: "faults" })}: `}</strong>
            {data.faults}
          </RowData>
        )}
      </Metadata>
      <Modal open={open} onClose={handleClose}>
        <ContentModal>
          {data.type === "photo" ? (
            <ModalImage src={`${data.url}?ts=${editTimestamp}`} alt={`media`} />
          ) : data.type === "video" ? (
            <ContentVideo>
              <ReactPlayer
                url={`${data.url}?ts=${editTimestamp}`}
                controls
                muted
                width="100%"
                height="100%"
              />
            </ContentVideo>
          ) : (
            <ModalImage src={NoImage} alt={`no image`} />
          )}
          {/* <ModalMetadata>
                        {
                            data.location &&
                            <RowData><strong>{`${intl.formatMessage({ id: 'location' })}: `}</strong>{data.location.name}</RowData>
                        }
                        {
                            data.responsible &&
                            <RowData><strong>{`${intl.formatMessage({ id: 'responsable' })}: `}</strong>{data.responsible.fullName}</RowData>
                        }
                        {
                            data.club &&
                            <RowData><strong>{`${intl.formatMessage({ id: 'club' })}: `}</strong>{data.club}</RowData>
                        }
                        {
                            data.horseRider &&
                            <RowData><strong>{`${intl.formatMessage({ id: 'rider' })}: `}</strong>{data.horseRider.fullName}</RowData>
                        }
                        {
                            data.jump &&
                            <RowData><strong>{`${intl.formatMessage({ id: 'jump' })}: `}</strong>{`${data.jump} ${intl.formatMessage({ id: 'meters' })}`}</RowData>
                        }
                        {
                            data.description &&
                            <RowData dangerouslySetInnerHTML={{ __html: data.description }} />
                        }
                    </ModalMetadata> */}
        </ContentModal>
      </Modal>
      <Modal open={openEdit} onClose={handleCloseEdit}>
        <NewMedia
          id={data.id}
          mediaData={data}
          url="mediacenter/trainings"
          handleClose={handleCloseEdit}
          setEditTimestamp={setEditTimestamp}
        />
      </Modal>
    </Root>
  );
};

export default Media;
