import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import { API_URL } from "../../config";
import axios from "axios";
import BasicEditor from "../textEditors/basicEditor";
import SelectInput from "../inputs/SelectInput";
import FileInput from "../inputs/fileInput";
import CheckIcon from "@mui/icons-material/Check";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import CheckInput from "../inputs/CheckInput";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";

/* Styled components */
const Root = styled.form`
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 600px;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
        props.justifyContent ? props.justifyContent : "space-between"};
  width: 100%;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;


const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const NewMedia = ({ id, url, mediaData, handleClose, setEditTimestamp }) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const dispatch = useDispatch();

    const { loadingAction, loadingProgress } = bindActionCreators(
        actionCreators,
        dispatch,
    );

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const [newAttachment, setNewAttachment] = React.useState(
        mediaData && url === "mediacenter/trainings"
            ? {
                name: mediaData.name,
                date: mediaData.date,
                description: mediaData.description,
                locationId: null,
                responsibleId: null,
                idHorseRider: mediaData.horseRider
                    ? {
                        label: mediaData.horseRider.fullName,
                        value: mediaData.horseRider.id,
                    }
                    : null,
                jump: mediaData.jump,
                completedTest: mediaData.jump === "completedTest" ? true : false,
                club: mediaData.club,
                showInWeb: mediaData.showInWeb,
                faults: mediaData.faults,
                result: mediaData.result,
            }
            : {
                name: "",
                date: new Date(),
                description: "",
                attachment: null,
                locationId: null,
                responsibleId: null,
                idHorseRider: null,
                jump: 0.8,
                club: "",
                showInWeb: false,
                faults: 0,
                result: 0,
            },
    );

    const handleEditorDescription = (e, dataeditor) => {
        setNewAttachment({
            ...newAttachment,
            description: dataeditor,
        });
    };

    const onChangeFile = (e) => {
        const { files } = e.target;
        if (files.length > 0) {
            setNewAttachment({
                ...newAttachment,
                attachment: files,
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewAttachment({
            ...newAttachment,
            [name]: value,
        });
    };

    const handleChangeAutocomplete = (name, value) => {
        setNewAttachment({
            ...newAttachment,
            [name]: value,
        });
    };

    const handleChangeDate = (newValue) => {
        setNewAttachment({
            ...newAttachment,
            date: newValue,
        });
    };

    const handleCheck = (name, value) => {
        console.log(newAttachment.jump);
        name === "completedTest" && value === true
            ? setNewAttachment({
                ...newAttachment,
                jump: "completedTest",
                completedTest: true,
            })
            : setNewAttachment({
                ...newAttachment,
                [name]: value,
            });
    };

    const handleAction = (e) => {
        e.preventDefault();
        loadingAction(loadingConstants.WAIT);
        let urlPost = `${API_URL}/mediacenter/${id}`;
        var formdata = new FormData();
        formdata.append("name", newAttachment.name);
        formdata.append("date", newAttachment.date);
        formdata.append("description", newAttachment.description);
        formdata.append("image", newAttachment.attachment?.[0] ?? null);
        formdata.append("showInWeb", newAttachment.showInWeb);
        formdata.append("idHorse", id);
        if (url !== "horses/media") {
            urlPost = `${API_URL}/${url}/${id}`;
        }
        if (url === "mediacenter/educations") {
            formdata.append("locationId", newAttachment.locationId?.value);
            formdata.append("responsibleId", newAttachment.responsibleId?.value);
        }
        if (url === "mediacenter/trainings") {
            formdata.append("idHorseRider", newAttachment.idHorseRider?.value);
            formdata.append("jump", newAttachment.jump);
            formdata.append("club", newAttachment.club);
            formdata.append("faults", newAttachment.faults);
            formdata.append("result", newAttachment.result);
        }
        if (mediaData) {
            const editUrl = `${API_URL}/mediacenter/trainings/${mediaData.id}`;
            axios
                .put(editUrl, formdata, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    //Listen for the onuploadprogress event
                    onUploadProgress: (e) => {
                        const { loaded, total } = e;
                        //Using local progress events
                        if (e.lengthComputable) {
                            let progress = (loaded / total) * 100;
                            loadingProgress(loadingConstants.PROGRESS, progress);
                        }
                    },
                })
                .then((response) => {
                    loadingAction(loadingConstants.READY);
                    setEditTimestamp(Date.now())
                    handleClose();
                })
                .catch((error) => {
                    loadingAction(loadingConstants.READY);
                    console.log(error);
                });
        } else {
            axios
                .post(urlPost, formdata, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    //Listen for the onuploadprogress event
                    onUploadProgress: (e) => {
                        const { loaded, total } = e;
                        //Using local progress events
                        if (e.lengthComputable) {
                            let progress = (loaded / total) * 100;
                            loadingProgress(loadingConstants.PROGRESS, progress);
                        }
                    },
                })
                .then((response) => {
                    loadingAction(loadingConstants.READY);
                    handleClose();
                })
                .catch((error) => {
                    loadingAction(loadingConstants.READY);
                    console.log(error.response?.data?.msg);
                    alert(intl.formatMessage({ id: "errorContact" }));
                });
        }
    };

    React.useEffect(() => {
        setEditorLoaded(true);
    }, []);

    return (
        <Root onSubmit={handleAction}>
            <Title>
                {mediaData ? translate("edit media") : translate("new media")}
            </Title>
            <TextField
                id="name"
                name="name"
                onChange={handleChange}
                value={newAttachment.name}
                label={intl.formatMessage({ id: "name" })}
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                required
            />
            <InputGroup>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                        renderInput={(props) => (
                            <StyledTextfield
                                size="small"
                                margin="dense"
                                width="45%"
                                {...props}
                            />
                        )}
                        label={intl.formatMessage({ id: "date" })}
                        value={newAttachment.date}
                        onChange={handleChangeDate}
                        required
                    />
                </LocalizationProvider>
                <CheckInput
                    width="45%"
                    label={intl.formatMessage({ id: "show web" })}
                    checked={newAttachment.showInWeb}
                    onChange={handleCheck}
                    name="showInWeb"
                />
            </InputGroup>
            {url === "mediacenter/educations" && (
                <InputGroup>
                    <SelectInput
                        value={newAttachment.locationId}
                        fieldlabel="name"
                        fieldvalue="id"
                        dataurl={`${API_URL}/locations`}
                        label={intl.formatMessage({ id: "location" })}
                        name="locationId"
                        onChange={handleChangeAutocomplete}
                        width="45%"
                        margin="dense"
                        size="small"
                        required
                    />
                    <SelectInput
                        value={newAttachment.responsibleId}
                        fieldlabel="fullName"
                        fieldvalue="id"
                        dataurl={`${API_URL}/human/all`}
                        label={intl.formatMessage({ id: "responsable" })}
                        name="responsibleId"
                        onChange={handleChangeAutocomplete}
                        width="45%"
                        margin="dense"
                        size="small"
                        required
                    />
                </InputGroup>
            )}
            {url === "mediacenter/trainings" && (
                <InputGroup>
                    <SelectInput
                        value={newAttachment.idHorseRider}
                        fieldlabel="fullName"
                        fieldvalue="id"
                        dataurl={`${API_URL}/human/all`}
                        label={intl.formatMessage({ id: "rider" })}
                        name="idHorseRider"
                        onChange={handleChangeAutocomplete}
                        width="45%"
                        margin="dense"
                        size="small"
                        required
                    />
                    <CheckInput
                        width="45%"
                        label={intl.formatMessage({ id: "completedTest" })}
                        checked={newAttachment.completedTest}
                        onChange={handleCheck}
                        name="completedTest"
                    />
                </InputGroup>
            )}
            {url === "mediacenter/trainings" && (
                <InputGroup>
                    <TextField
                        id="club"
                        name="club"
                        onChange={handleChange}
                        value={newAttachment.club}
                        label={intl.formatMessage({ id: "club" })}
                        variant="outlined"
                        width="45%"
                        margin="dense"
                        size="small"
                        required
                    />
                    <StyledTextfield
                        id="jump"
                        type="number"
                        name="jump"
                        inputProps={{ min: 0.8, max: 1.65, step: 0.05 }}
                        onChange={handleChange}
                        disabled={newAttachment.completedTest ? true : false}
                        value={parseFloat(newAttachment.jump).toFixed(2)}
                        label={`${intl.formatMessage({ id: "jump" })}  (${intl.formatMessage({ id: "meters" })})`}
                        variant="outlined"
                        width="45%"
                        margin="dense"
                        size="small"
                        required
                    />
                </InputGroup>
            )}
            {url === "mediacenter/trainings" && (
                <InputGroup>
                    <StyledTextfield
                        id="faults"
                        type="number"
                        name="faults"
                        onChange={handleChange}
                        value={newAttachment.faults}
                        label={intl.formatMessage({ id: "faults" })}
                        variant="outlined"
                        width="45%"
                        margin="dense"
                        size="small"
                        helperText={intl.formatMessage({ id: "insert numbers only" })}
                        required
                    />
                    <StyledTextfield
                        id="result"
                        type="number"
                        name="result"
                        onChange={handleChange}
                        value={newAttachment.result}
                        label={intl.formatMessage({ id: "result" })}
                        variant="outlined"
                        width="45%"
                        margin="dense"
                        size="small"
                        helperText={intl.formatMessage({ id: "insert numbers only" })}
                    />
                </InputGroup>
            )}
            <BasicEditor
                name="description"
                value={newAttachment.description}
                onChange={handleEditorDescription}
                editorLoaded={editorLoaded}
                maxContentHeight="100px"
                minContentHeight="100px"
                placeholder={intl.formatMessage({ id: "insertDescription" })}
            />
{/* TODO:  Acá iría un preview del archivo seleccionado*/}
            <FileInput
                file={
                    mediaData && !newAttachment.attachment
                        ? mediaData.nameFile
                        : newAttachment.attachment
                }
                error={false}
                onChange={onChangeFile}
                // required={mediaData ? false : true}
            />
            <Button bg={theme.palette.accent} color={theme.palette.base}>
                <CheckIcon />
            </Button>
        </Root>
    );
};

export default NewMedia;
